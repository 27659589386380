import { useQuery } from '@apollo/client';
import { Button, Card, Col, Image, Row, Space } from 'antd';
import clsx from 'clsx';
import { findIndex } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from '../../apollo';
import { AudioIcon } from '../../assets/svg';
import {
  APPROVAL_TYPE,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import AudioPlayerModal from '../../components/AudioPlayerModal';
import EllipsisText from '../../components/EllipsisText';
import AdditionalComment from './AdditionalComment';
import ApprovalPopUp from './ApprovalPopUp';
import ApproveModal from './ApproveModal';
import ApproverStageItemDetails from './ApproverStageItemDetails';
import EqcSummary from './EqcSummary';
import StageSummary from './StageSummary';
import { GET_STAGE_HISTORY } from './graphql/Queries';

const ApproverLink = () => {
  const { id, token } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [isBannerBlinking, setIsBannerBlinking] = useState(false);
  const [commentData, setCommentData] = useState({
    eqcStage: [],
    eqcStageItems: []
  });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [additionalComment, setAdditionalComment] = useState([]);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audios, setAudios] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const { data, loading, refetch } = useQuery(GET_STAGE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
      token: token
    }
  });
  const eqcStageData = data?.getEqcStageHistoryWithoutAuth;

  useMemo(() => {
    if (isBannerBlinking) {
      setTimeout(() => {
        setIsBannerBlinking(false);
      }, 5000);
    }
  }, [isBannerBlinking]);

  useEffect(() => {
    if (
      data &&
      data?.getEqcStageHistoryWithoutAuth?.status !==
        STAGE_STATUS.APPROVAL_PENDING
    ) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getRemarkAudios = (value) => {
    return value?.length ? (
      <div
        className="audio-player-wrapper"
        onClick={() => {
          setShowAudioModal(true);
          setAudios(value);
          Event(GA_EVENT.DOWNLOAD_AUDIO, {
            label: GA_LABEL.DOWNLOAD_AUDIO,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href
          });
        }}
      >
        <AudioIcon />
        {value?.length - 1 > 0 && (
          <div className="extra-count">{`+${value?.length - 1}`}</div>
        )}
      </div>
    ) : (
      '-'
    );
  };
  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href
              });
            }
          }
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      '-'
    );
  };

  return (
    <>
      {showModal && (
        <ApprovalPopUp
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
        />
      )}

      <ApproveModal
        showModal={showApproveModal}
        setShowModal={setShowApproveModal}
        data={data}
        title={title}
        status={status}
        commentData={commentData}
        additionalComment={additionalComment}
      />

      {eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE && (
        <div
          className={clsx(
            'on-site-approval-bar',
            isBannerBlinking && 'blinking-banner'
          )}
        >
          <dt className="grace-bar">
            <div className="grace-bar-text">
              Approval from web is restricted as this EQC require approver to be
              on site. Please give approval from mobile application.
            </div>
          </dt>
        </div>
      )}
      <div
        className={clsx(
          'approval-link',
          eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE &&
            isDesktopViewport
            ? 'mt-30'
            : 'mt-50'
        )}
      >
        <div className="mb-24">
          <EqcSummary data={data} loading={loading} />
        </div>
        <div className="mb-24">
          <StageSummary
            data={data}
            refetch={refetch}
            setCommentData={setCommentData}
            commentData={commentData}
            loading={loading}
          />
        </div>
        {eqcStageData?.byPassRemark && (
          <Card loading={loading} className="mb-24">
            <Card className="comment-card bypass-card" loading={loading}>
              {eqcStageData?.byPassRemark && (
                <div>
                  <div className="remark-title">Pass Remark</div>
                  <div className="d-flex align-center justify-between mb-5">
                    <EllipsisText
                      className="additional-title"
                      text={eqcStageData?.byPassRemark || '-'}
                    />
                  </div>
                </div>
              )}
              <Row gutter={10}>
                <Col span={isDesktopViewport ? 12 : 24}>
                  {eqcStageData?.byPassedImages?.length > 0 && !loading && (
                    <Row className="flex-vertical mt-24">
                      <div className="remark-title">Remark Images</div>
                      <div
                        className={
                          isDesktopViewport
                            ? 'd-flex justify-between align-end'
                            : 'd-flex flex-vertical'
                        }
                      >
                        <div className="mt-5">
                          {getPhotos(
                            eqcStageData?.byPassedImages,
                            eqcStageData?.byPassRemark
                          )}
                        </div>
                      </div>
                    </Row>
                  )}
                </Col>
                <Col span={isDesktopViewport ? 12 : 24}>
                  {eqcStageData?.byPassedAudios?.length > 0 && !loading && (
                    <Row className="flex-vertical mt-24">
                      <div className="remark-title">Remark Audio</div>
                      <div
                        className={
                          isDesktopViewport
                            ? 'd-flex justify-between align-end'
                            : 'd-flex flex-vertical'
                        }
                      >
                        <div className="mt-5">
                          {getRemarkAudios(eqcStageData?.byPassedAudios)}
                        </div>
                      </div>
                    </Row>
                  )}
                </Col>
              </Row>
            </Card>
          </Card>
        )}
        <div>
          <ApproverStageItemDetails
            data={data}
            refetch={refetch}
            setCommentData={setCommentData}
            commentData={commentData}
            loading={loading}
            setIsBannerBlinking={setIsBannerBlinking}
          />
        </div>
        <div>
          <AdditionalComment
            data={data}
            refetch={refetch}
            setCommentData={setCommentData}
            commentData={commentData}
            setAdditionalComment={setAdditionalComment}
            additionalComment={additionalComment}
            setIsBannerBlinking={setIsBannerBlinking}
            approvalType={eqcStageData?.approvalType}
          />
        </div>
        {audios?.length > 0 && (
          <AudioPlayerModal
            audios={audios}
            showModal={showAudioModal}
            setShowModal={setShowAudioModal}
          />
        )}
        {data &&
          data?.getEqcStageHistoryWithoutAuth?.status ===
            STAGE_STATUS.APPROVAL_PENDING && (
            <div className="d-flex justify-center">
              <Button
                shape="round"
                className={clsx(
                  eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE &&
                    'ant-btn-default ant-btn-disabled'
                )}
                onClick={() => {
                  if (eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE) {
                    setIsBannerBlinking(true);
                  } else {
                    if (
                      findIndex(
                        commentData?.eqcStageItems,
                        (item) =>
                          item.generalRemark && item.generalRemark !== null
                      ) === -1 &&
                      !additionalComment?.remark
                    ) {
                      return toast({
                        message: data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                          ? 'To reject RFI at least one comment is require.'
                          : 'If you want Inspector to REDO, please add at least one comment.',
                        type: 'error'
                      });
                    }
                    setShowApproveModal(true);
                    setTitle(
                      data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                        ? 'REJECT'
                        : 'REDO'
                    );
                    setStatus(
                      data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                        ? STAGE_STATUS.REJECTED
                        : STAGE_STATUS.REDO
                    );
                  }
                }}
              >
                {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                  ? 'Reject'
                  : 'Redo'}
              </Button>
              <Button
                shape="round"
                className={clsx(
                  eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE &&
                    'ant-btn-default ant-btn-disabled'
                )}
                type="primary"
                onClick={() => {
                  if (eqcStageData?.approvalType === APPROVAL_TYPE.ON_SITE) {
                    setIsBannerBlinking(true);
                  } else {
                    setShowApproveModal(true);
                    setTitle(
                      data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                        ? 'ACCEPT'
                        : 'APPROVE'
                    );
                    setStatus(
                      data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                        ? STAGE_STATUS.ACCEPTED
                        : STAGE_STATUS.APPROVED
                    );
                  }
                }}
              >
                {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                  ? 'Accept'
                  : 'Approve'}
              </Button>
            </div>
          )}
      </div>
    </>
  );
};

export default ApproverLink;
